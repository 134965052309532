import { AUTH } from './types'

const adminLogin = (data, history) => ({
  type: AUTH.ADMIN_LOGIN,
  payload: data,
  history
})

const adminLoginSuccess = (data) => ({
  type: AUTH.ADMIN_LOGIN_SUCCESS,
  payload: data
})

const adminLogout = () => ({
  type: AUTH.ADMIN_LOGOUT
})

const userLogin = (data, toggleModal) => ({
  type: AUTH.USER_LOGIN,
  payload: data,
  toggleModal
})

const userLoginSuccess = (data) => ({
  type: AUTH.USER_LOGIN_SUCCESS,
  payload: data
})

const userLogout = () => ({
  type: AUTH.USER_LOGOUT
})

const userCheckoutWithoutLogin = (data) => ({
  type: AUTH.USER_CHECKOUT_WITHOUT_LOGIN,
  payload: data
})

export {
  adminLogin,
  adminLoginSuccess,
  userLogin,
  userLoginSuccess,
  adminLogout,
  userLogout,
  userCheckoutWithoutLogin
}
