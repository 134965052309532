import { takeLatest, put, call } from 'redux-saga/effects'
import { AUTH } from '../actions/types'
import { adminLoginSuccess, userLoginSuccess } from '../actions/auth'
import { loading, loadingFinish } from '../actions/loading'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getCart, getFavourites } from '../actions/user'

const baseURL =
  process.env.REACT_APP_PROD_MODE === 'test'
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_API_URL_LIVE

function * handleUserLogin ({ payload, toggleModal }) {
  const apiCall = async () => {
    try {
      return await axios.post(`${baseURL}/auth`, payload)
    } catch (error) {
      throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      const data = {
        ...response.data.decodeDetails,
        token: response.data.token
      }
      yield put(userLoginSuccess(data))
      toast.success(response.data.message)

      if (localStorage.getItem('cart')) {
        const cart = JSON.parse(localStorage.getItem('cart')).map(item =>
          // console.log('this is items', item)
          ({
            noItems: item.noItems,
            productId: item.productId,
            productPrice: item.productPrice,
            variationName: item.variationName,
            color: item.color,
            size: item.size,
            tags: item.tags
          })
        )

        const apiCall = async () => {
          try {
            return await axios.post(
              `${
                process.env.REACT_APP_PROD_MODE === 'test'
                  ? process.env.REACT_APP_API_URL_TEST
                  : process.env.REACT_APP_API_URL_LIVE
              }/cart/add`,
              { payload: cart },
              {
                headers: {
                  'x-auth-token': response.data.token
                }
              }
            )
          } catch (error) {
            throw error
          }
        }

        try {
          const response = yield call(apiCall)

          if (response.status === 201) {
            toast.success(response.data.message)
            localStorage.removeItem('cart')
          }
        } catch (error) {
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
          }
        }
      }

      yield put(getCart())
      yield put(getFavourites())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    } else {
      toast.error('An error occurred! Please check your internet connection.')
    }
  } finally {
    yield put(loadingFinish())
  }
}

function * handleAdminLogin ({ payload, history }) {
  const apiCall = async () => {
    try {
      return await axios.post(`${baseURL}/adminUser/login`, payload)
    } catch (error) {
      throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      const data = {
        ...response.data.decodeDetails,
        token: response.data.token
      }
      yield put(adminLoginSuccess(data))
      history.push('/admin')
      toast.success(response.data.message)
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    } else {
      toast.error('An error occurred! Please check your internet connection.')
    }
  } finally {
    yield put(loadingFinish())
  }
}

function * handleCheckWithoutReg ({ payload }) {
  try {
    yield put(loading())

    yield put(userLoginSuccess(payload))
    toast.success('User data collected successfully')
  } catch (error) {

  } finally {
    yield put(loadingFinish())
  }
}

function * handleUserLogout () {}

const authSaga = [
  takeLatest(AUTH.ADMIN_LOGIN, handleAdminLogin),
  takeLatest(AUTH.USER_LOGIN, handleUserLogin),
  takeLatest(AUTH.USER_LOGOUT, handleUserLogout),
  takeLatest(AUTH.USER_CHECKOUT_WITHOUT_LOGIN, handleCheckWithoutReg)
]

export default authSaga
