const AUTH = {
  ADMIN_LOGIN: 'ADMIN_LOGIN',
  ADMIN_LOGOUT: 'ADMIN_LOGOUT',
  ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGOUT: 'USER_LOGOUT',
  USER_CHECKOUT_WITHOUT_LOGIN: 'USER_CHECKOUT_WITHOUT_LOGIN'

}

const LOADING = {
  LOADING: 'LOADING',
  FINISH: 'FINISH',
  UPDATING_CART: 'UPDATING_CART',
  UPDATING_CART_FINISH: 'UPDATING_CART_FINISH'
}

const ADMIN = {
  EDITED_PRODUCT_PAGINATION_DETAILS: 'EDITED_PRODUCT_PAGINATION_DETAILS'
}
const USER = {
  GET_FAVOURITES: 'GET_FAVOURITES',
  GET_FAVOURITES_SUCCESS: 'GET_FAVOURITES_SUCCESS',
  GET_CART: 'GET_CART',
  ADD_ITEM: 'ADD_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  ADD_FAVOURITE: 'ADD_FAVOURITE',
  GET_CART_SUCCESS: 'GET_CART_SUCCESS',
  CLEAR_CART: 'CLEAR_CART',
  CLEAR_CART_SUCCESS: 'CLEAR_CART_SUCCESS',
  UPDATE_ITEM: 'UPDATE_ITEM',
  APPLY_COUPON: 'APPLY_COUPON',
  APPLY_DISCOUNT: 'APPLY_DISCOUNT',
  REMOVE_COUPON: 'REMOVE_COUPON',
  LAST_CATEGORY: 'LAST_CATEGORY',
  CHECKOUT_WITHOUT_REGISTER: 'CHECKOUT_WITHOUT_REGISTER',
  GET_CURRENCY: 'GET_CURRENCY',
  GET_CURRENCY_SUCCESS: 'GET_CURRENCY_SUCCESS'
}

const CURRENCY = {
  GET_CURRENCY: 'GET_CURRENCY',
  GET_CURRENCY_SUCCESS: 'GET_CURRENCY_SUCCESS'
}

const MODAL = {
  LOGIN: 'LOGIN'
}

export { AUTH, LOADING, USER, MODAL, ADMIN, CURRENCY }
