import { MODAL } from '../actions/types'

const userReducer = (state = { login: false }, action) => {
  switch (action.type) {
    case MODAL.LOGIN:
      return { ...state, login: action.payload }
    default:
      return state
  }
}

export default userReducer
