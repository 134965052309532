import { USER, ADMIN } from '../actions/types'

export const getFavourites = () => ({
  type: USER.GET_FAVOURITES
})

export const getFavouritesSuccessful = data => ({
  type: USER.GET_FAVOURITES_SUCCESS,
  payload: data
})

export const addToFavourite = data => ({
  type: USER.ADD_FAVOURITE,
  payload: data
})

export const addItem = data => ({
  type: USER.ADD_ITEM,
  payload: data
})

export const removeItem = data => ({
  type: USER.REMOVE_ITEM,
  payload: data
})
export const getCart = () => ({
  type: USER.GET_CART
})

export const getCartSuccessful = data => ({
  type: USER.GET_CART_SUCCESS,
  payload: data
})

export const clearCart = () => ({
  type: USER.CLEAR_CART
})

export const clearCartSuccess = data => ({
  type: USER.CLEAR_CART_SUCCESS,
  payload: data
})

export const updateItem = data => ({
  type: USER.UPDATE_ITEM,
  payload: data
})

export const applyCoupon = data => ({
  type: USER.APPLY_COUPON,
  payload: data
})

export const applyDiscount = data => ({
  type: USER.APPLY_DISCOUNT,
  payload: data
})

export const removeCoupon = data => ({
  type: USER.REMOVE_COUPON,
  payload: data
})

export const lastCategory = data => ({
  type: USER.LAST_CATEGORY,
  payload: data
})

export const editedProductPaginationDetails = data => ({
  type: ADMIN.EDITED_PRODUCT_PAGINATION_DETAILS,
  payload: data
})

export const getCurrencies = () => ({
  type: USER.GET_CURRENCY
})

export const getCurrenciesSuccessful = data => ({
  type: USER.GET_CURRENCY_SUCCESS,
  payload: data
})
