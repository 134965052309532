import { AUTH, USER, CURRENCY } from '../actions/types'

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH.USER_LOGIN_SUCCESS:
      const user = action.payload
      localStorage.setItem('user', JSON.stringify(user))
      return { ...state, ...user, isLoggedIn: true }

    case AUTH.USER_LOGOUT:
      localStorage.removeItem('user')
      return {}

    case USER.GET_CART_SUCCESS:
      return { ...state, cart: { ...action.payload } }

    case USER.GET_FAVOURITES_SUCCESS:
      return { ...state, favourites: action.payload }

    case USER.APPLY_COUPON:
      return { ...state, cart: { ...action.payload } }

    case USER.REMOVE_COUPON:
      return { ...state, cart: { ...action.payload } }

    case USER.APPLY_DISCOUNT:
      return { ...state, cart: { ...action.payload } }

    case USER.LAST_CATEGORY:
      return { ...state, lastCategory: action.payload }

    case AUTH.USER_CHECKOUT_WITHOUT_LOGIN:
      return { ...state, checkWithoutReg: true }

    case USER.GET_CURRENCY_SUCCESS:
      return { ...state, currencies: action.payload }

    default:
      return state
  }
}

export default userReducer
