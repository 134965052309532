import { combineReducers } from 'redux'
import admin from './adminReducer'
import user from './userReducer'
import loading from './loadingReducer'
import modal from './modalReducer'
import currency from './currencyReducer'

const rootReducer = combineReducers({
  admin,
  loading,
  user,
  currency,
  modal
})

export default rootReducer
