import React from 'react'
import { Spinner } from 'react-bootstrap'
import '../../styles/loader.scss'

export default function Loader (props) {
  if (props.error) {
    return (
      <div className='error-layout'>
        <p className='error-message'>
          Seems your network connection is bad today
        </p>
        <button
          size='medium'
          variant='outlined'
          color='primary'
          onClick={() => props.retry}
        >
          Retry
        </button>
      </div>
    )
  } else if (props.pastDelay) {
    return (
      <div
        style={{
          height: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Spinner animation='grow' variant='primary' className='spinn' />
      </div>
    )
  } else {
    return null
  }
}
