import { CURRENCY } from '../actions/types'

const currencyReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENCY.GET_CURRENCY_SUCCESS:
      return { ...state, currencies: action.payload }
    default:
      return state
  }
}

export default currencyReducer
