import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import ReactGA from 'react-ga4'
import saga from './store/saga'
import { HashRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { ToastContainer } from 'react-toastify'
import { datadogRum } from '@datadog/browser-rum'
import 'bootstrap/dist/css/bootstrap.min.css'

import rootReducer from './store/reducers'

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = composeWithDevTools({
  trace: true,
  traceLimit: 25
})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(saga)

ReactGA.initialize('G-P2NHKT4BTF')

if (process.env.REACT_APP_PROD_MODE === 'live') {
  datadogRum.init({
    applicationId: 'e9b89370-d414-40ee-943f-92dfba26dabc',
    clientToken: 'pub87645e762e79854d4acd5bfa5be95746',
    site: 'us5.datadoghq.com',
    service: 'lidstores',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    premiumSampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input'
  })

  datadogRum.startSessionReplayRecording()
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <ToastContainer />
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
