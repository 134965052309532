import { takeLatest, put, call, cancel, takeEvery } from 'redux-saga/effects'
import { CURRENCY } from '../actions/types'
import {
  getCurrencies,
  getCurrenciesSuccessful
} from '../actions/currency'
import {
  loading,
  loadingFinish
} from '../actions/loading'

import axios from 'axios'
import { toast } from 'react-toastify'

const baseURL =
  process.env.REACT_APP_PROD_MODE === 'test'
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_API_URL_LIVE

function * handleGetCurrencies () {
  const apiCall = async () => {
    try {
      return await axios.get(`${baseURL}/foreign-currency/get/dollars`)
    } catch (error) {
      throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    // getCurrenciesSuccessful()
    if (response.status === 200) {
      yield put(getCurrenciesSuccessful(response.data.foreignCurrency))
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(loadingFinish())
  }
}

const currencySaga = [
  takeLatest(CURRENCY.GET_CURRENCY, handleGetCurrencies)
]

export default currencySaga
