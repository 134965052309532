import { AUTH, ADMIN } from '../actions/types'

const adminReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH.ADMIN_LOGIN_SUCCESS:
      const admin = action.payload
      localStorage.setItem('admin', JSON.stringify(admin))
      return { ...state, ...admin, isLoggedIn: true }

    case AUTH.ADMIN_LOGOUT:
      localStorage.removeItem('admin')
      return { isLoggedIn: false }

    case ADMIN.EDITED_PRODUCT_PAGINATION_DETAILS:
      const { payload } = action
      return { ...state, editedProductPaginationDetails: payload }

    default:
      return state
  }
}

export default adminReducer
